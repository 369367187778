@value teal, lighter-grey, lighter-teal, lightest-teal, tableGridGrey from '../../../assets/styles/variables/colors.css';

.tableContainer {
  margin: 20px 0;
  height: auto;
}
.defaultView {
  height: calc(90vh - 150px) !important;
  text-align: center;
  display: flex;
  justify-content: center;
}
.tableRowHeading {
  border-bottom: 1px solid tableGridGrey;
  font-size: 16px;
  font-family: 'SF' Compact Text;
}
.tableRowHeading > div {
  padding: 30px 15px 15px 15px !important;
  font-weight: bold;
  text-transform: capitalize;
}
.selectedRow {
  font-weight: bold;
  background-color: lighter-teal;
}
.tableBody {
  height: 75vh;
  overflow-y: scroll;
}
.tableBody .listRow:hover {
  background-color: lighter-teal;
}
.tableBody .listRow {
  height: 70px;
  border-bottom: 1px solid #e9e9e9;
}
.tableBody .listRow .listRowItem:first-child {
  font-size: 12px;
  padding: 15px 15px;
  display: flex;
  align-items: center;
  height: inherit;
}
.listRowItem > div:first-child {
  width: 70%;
  text-transform: capitalize;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.tableBody .listRow:last-child {
  border-bottom: 1px solid tableGridGrey;
}
.notFound {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  color: white;
  background-color: teal;
  font-size: 10px;
  margin-left: 10px;
  line-height: 0px;
}
