
@value popover from '../Popovers.module.css';

.multiSelectListPopover {
  composes: popover;
  min-width: 200px;
  max-width: 400px;
  overflow-x: hidden;
  /*max-height: 600px;*/
  /*overflow-y: scroll;*/
  padding: 11px 0;
}

.popoverSelect :global(.interpretationSelect.react-select-filter-selector__menu) {
  position: relative;
}

/*.popoverSelect :global(.interpretationSelect.react-select-filter-selector__menu-list) {*/
/*  max-height: none;*/
/*}*/
