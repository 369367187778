.telekardia{
@value white #FFFFFF;
@value waveformBlack #3D2525;
.ekgRecording {
  overflow-y: scroll;
  position: relative;
  background-color: white;
}
.topSVG {
  background-color: transparent;
  position: absolute;
  pointer-events: none;
}
.topSVG path {
  fill: none;
  stroke: waveformBlack;
  stroke-width: 1.5px;
  stroke-linejoin: round;
}
.canvas {
  position: absolute;
}
.ekgRecording::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ekgRecording {
-ms-overflow-style: none !important;  /* IE and Edge */
scrollbar-width: none !important;  /* Firefox */
}
}
.ekgRecording::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ekgRecording {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}