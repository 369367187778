@value sb from '../../styles/typography.css';
@value lightest-grey from '../../styles/variables/colors.css';

.noEKGPreview {
  /* background-color: lightest-grey; */
  /* padding: 16px 0 0 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  /* composes: sb; */
}
