// .telekardia {
//   body,
//   html {
//     background-color: gray;
//   }
//   .OT_subscriber {
//     float: left; 
//     margin-right: -7em;  
//   }
//   .OT_publisher {
//     float: left;
//     // margin-right: -7em;  
//   }
//   .OTSubscriberContainer {
//     font-family: 'uomo';
//     width: 240px !important;
//     height: 200px !important;
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
//     z-index: 5;
//     border-radius: 5px;
//   }
//   .OTPublisherContainer {
//     font-family: 'uomo';
//     width: 120px !important;
//     height: 100px !important;
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
//     z-index: 5;
//     border-radius: 5px;
//   }
//   .error {
//     color: red;
//   }
// }

body, html {
  background-color: #fff;
}
// .OT_subscriber {
//   width: 100% !important;
//   // height: calc(100vh - 110px) !important;
//   height: 400px !important;
//   top: 46px !important;
//   z-index: 0 !important;
// }
// .OT_publisher {
//   width: 164px !important;
//   height: 164px !important;
//   background-color: gray;
//   z-index: 1 !important;
//   position: absolute;
//   bottom: 8px;
//   left: 20px;
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
//   border-radius: 5px;
// }
// .OTSubscriberContainer {  
//   width: 652px !important;
//   height: 460px !important;
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.49);
//   top: 0px !important;
//   z-index: 0 !important;
//   border-radius: 5px;
// }
.error {
  color: red;
}
.control_icons {
  width:48px;
  height:48px;
  border-radius: 50% !important;
  color:#fff !important;
  background: #1b1e21;
}
.video_icon {
  background:#e04747 !important;
}
.audio_icon {
  background: #0c5460 !important;
}
.f20 .MuiSvgIcon-root{
  font-size:2.5rem !important;
}
.pos_absolute {
  position: absolute;
  width: 100%;
  bottom: 0;
}

