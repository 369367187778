.telekardia{
@value gridRed, dark-grey, light-grey from '../../styles/variables/colors.css';

.grid {
  stroke-width: 1px;
  stroke: gridRed;
  shape-rendering: crispedges;
  stroke-opacity: .2;
}
.xGridSmall {
  composes: grid;
}

.xGrid {
  composes: grid;
}
.xGridDark {
  composes: grid;
}
.yGrid {
  composes: grid;
}
.yGridDark {
  composes: grid;
  stroke-opacity: .4;
}
}