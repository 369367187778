/* @media (min-width: 768px) and (max-width: 991.98px) {
    aside {
        display: none !important;
    }
    aside + div {
        width: 100% !important;
    }
}

@media (min-width: 992px) and (max-width: 1299.98px) {
    aside {
        display: none !important;
    }

    aside + div {
        width: 100% !important;
    }
} */

.ui.popup.telekardia::before
{
    transform: rotate(0deg) !important;
    z-index: 2;
    box-shadow:none !important;
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #efefefd4;
    background: transparent !important;
    bottom: -10px !important;
  }

