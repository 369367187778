@value dark-grey, light-grey from '../../../../assets/styles/variables/colors.css';
@value chartFontSize 12px;

.xAxis {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}
.xAxisSvg {
  width: auto;
}
.monthLine {
  stroke: light-grey;
  stroke-width: 2px;
}
.dayTick text {
  fill: dark-grey;
  font-size: chartFontSize;
}
