/* eslint-disable */
.telekardia{
.previewGridNoTranslate {
  width: 600px;
  transform: scale(.6, .6);
  transform-origin: 0 0;
  background-color: #fff;
}
.previewGrid {
  composes: previewGridNoTranslate;
  transform: scale(.6, .6) translate(0, -17px);
}
}