@value medium-grey, bg-white, darker-grey, black, silver, dark-white, light-grey, kardia-green, teal from '../../../../../../assets/styles/variables/colors.css';

@value h1, h2, h3, lbl, m, sb from '../../../../../../assets/styles/typography.css';
@value minContentHeight from '../../../../../../assets/styles/variables/global.css';
@value medium-viewport from '../../../../../../assets/styles/mediaQueries.css';
@value newDefaultButton, newDarkDefaultButton from '../../../../../../assets/styles/formElements.css';
@value pageContainer, contentWrapper, popoverBlanket, headerText, headerLine, headerWrapper, nameText, yearsOfAge, mrnTitle, mrnValue from '../../../../../../assets/styles/page.css';
@value formWrapper, submitWrapper, buttonWrapper, submit, formTop, formTitle, formSection from '../../../../../../assets/styles/patientForm.css';

.connectionStatus {
  composes: h2;
  color: black;
  text-transform: capitalize;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 44px;
  font-size: 18px;
  font-weight: 600;
}
.connectionStatus .connected {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: kardia-green;
  margin-right: 23px;
}

.exportReportWrapper {
  position: relative;
}
.exportReportBtn {
  composes: newDefaultButton;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  height: 36px;
  padding: 6px 16px;
  margin-right: 10px;
  background-color: bg-white;
  box-shadow: none;
  border-color: medium-grey;
}
.exportLoading {
  margin-right: 8px;
}
.exportReportBtn span {
  white-space: nowrap;
}

.exportReportBtnActive {
  composes: exportReportBtn;
  border-color: teal;
}
.exportReportBtn:hover {
  background-color: bg-white;
  border-color: teal;
}
.exportReportBtn > svg {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.patientFile {
  composes: pageContainer;
  min-height: minContentHeight;
}

.infoGroup {
  display: flex;
  flex-direction: column;
  /* padding: 11px 16px 4px; */
  padding: 11px 16px 11px;
}
.info {
  display: flex;
  flex-direction: row;
}
.info p {
  margin: 0;
  height: 14px;
  display: flex;
  align-items: flex-end;
}
.info p:first-child {
  margin-bottom: 2px;
}
.title {
  composes: lbl;
}
.titleShort {
  composes: title;
  width: 32px;
}
.titleMedium {
  composes: title;
  width: 60px;
}
.titleLong {
  composes: title;
  width: 200px;
}
.values {
  composes: m;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ecgPage {
  composes: contentWrapper;
  /* background-color: white; */
}

.ecgPage > * {
  margin: 0 30px;
}
.ecgPage > *:first-child {
  padding-top: 16px;
  margin-bottom: 24px;
}
.ecgGraph {
  min-height: 185px;
  /* display: flex; */
  /* justify-content: center; */
  margin-bottom: 20px;
}

.tableContent {
  margin-bottom: 24px;
  /* text-align: center; */
}

.ecgPage .tableContent {
  margin-bottom: 0;
}

.toolbarWrapper {
  padding: 11px 16px;
}

.pendingMessage svg {
  width: 115px;
  height: 147px;
  margin-right: 100px;
  margin-left: 165px;
}
.qrCode {
  width: 130px;
  height: 147px;
  margin-right: 100px;
  margin-left: 95px;
  padding-bottom: 10px;
}
.message {
  font-size: 16px;
  line-height: 26px;
}
.formHeading {
  margin-left: 86px;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 40px;
}
.textWeight {
  font-weight: 700;
}
.textDiv {
  margin-top: 11px;
}
.alivecorLogo {
  padding-top: 25px;
  padding-left: 50px;
  font-size: x-large;
}

.editEnrollPatient {
  composes: submitWrapper;
  margin-top: -60px;
  margin-right: -835px;
  margin-bottom: 20px;
}

.headings {
  font-size: 16px;
  font-family: 'Work Sans';
  padding: 20px 20px 0;
}

@media screen and (max-height: 767px) {
  .ecgPage {
    overflow-y: auto;
  }
}
