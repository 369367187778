@value popover from '../Popovers.module.css';

.sliderPopover {
  composes: popover;
  min-width: 240px;
  min-height: 120px;
}

.sliderTitle {
  margin: 11px 16px 12px;
  height: 24px;
}
