.slider {
  margin: 0 30px;
}

.sliderValues {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 12px 14px;
}

.sliderValue {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30px;
}

.sliderValue p {
  margin: 0;
}
.sliderValue p:first-child {
  font-size: 11px;
  font-weight: 600;
  font-family: 'proxima-nova';
  color: silver;
}
.sliderValue p:last-child {
  font-size: 14px;
  font-weight: 700;
  font-family: 'proxima-nova';
  color: black;
  margin: 5px 0 0;
}