@value teal, kardia-red, kardia-green, kardia-grey,silver from '../../assets/styles/variables/colors.css';

.validationRules {
  text-align: left;
  font-family: 'Work Sans';
  color:silver;
}
.rulesContainer{
  display: flex;
}
.rule {
  display: flex;
  align-items: center;
  margin: 6px 0;
  color:kardia-grey;
  font-family: 'Work Sans';
}
.validStatus {
  margin-right: 6px;
}
.color {
  composes: validStatus;
}
.color .fail path {
  fill: kardia-grey;
}
.color .pass path {
  fill: kardia-grey;
}
.fail,
.pass {
  display: flex;
}
.fail svg {
  height: 20px;
  width: 20px;
}
.fail path {
  fill: teal;
}
.pass svg {
  height: 20px;
  width: 20px;
}
.pass path {
  fill: teal;
}
