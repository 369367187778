@value headerText, headerLine, headerHeight from '../../assets/styles/page.css';
@value white, teal, light-teal from '../../assets/styles/variables/colors.css';
@value newDarkDefaultButton from '../../assets/styles/formElements.css';
@value headerPaddingBottom 60px;
@value tabsHeight 43px;

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 26px 0 headerPaddingBottom; */
  position: relative;
  height: headerHeight;
}

.headerWithTabs {
  composes: header;
  padding-bottom: calc(headerPaddingBottom - tabsHeight);
}

.contentHeaderLeftGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;

  /* width: calc(100% - 368px - 40px); */
}

.contentHeaderFullWidth {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  width: 500px;
}
.contentHeaderRightGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  /* width: 368px; */
}

/* .headerText {
  composes: headerText;
} */

.headerTextWithBack {
  composes: headerText;
  padding-left: 11px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.addPatientBtn {
  composes: newDarkDefaultButton;
  font-size: 14px;
  text-transform: uppercase;
  min-width: 162px;
  width: 162px;
  height: 36px;
  padding: 6px 0;
}
.addPatientBtn:hover {
  color: white;
}
.addPatientBtn svg {
  width: 22px;
  height: 22px;
  fill: white;
  margin-right: 8px;
}
.back {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
}
.back svg {
  height: 44px;
  width: 44px;
  fill: teal;
}

.back:hover {
  fill: light-teal;
}
