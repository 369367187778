@value white from '../../assets/styles/variables/colors.css';

.graph {
  height: 100%;
  background-color: white;
  position: relative;
  width: 100%;
}
.circle {
  stroke: #fff;
  stroke-width: 1px;
}

.unselected {
  opacity: 0.2;
}

.selected {
  cursor: pointer;
}
