.pcpInputTitle {
  margin-bottom: 8;
  margin-left: '0.7rem';
}
.pcpbody2 {
  margin-top: 16;
}
.formcontrol {
  width: '100%';
}
.customInputStyle {
  display: 'flex !important';
  flex-direction: 'column';
}
.headerwrapper {
  display: 'flex';
  justify-content: 'space-between';
  padding: '16px';
  margin-left: '10px';
}
.schedulerow {
  margin-top: '24px !important';
  display: 'flex';
  justify-content: 'flex-end';
}
