@value popover, label from '../Popovers.module.css';

.dropdownSearchPopover {
  composes: popover;
  min-width: 240px;
  max-width: 250px;
  padding: 11px 16px;
}

.dropdownSearchPopover::before{
  position: absolute;
  content: "";
  top: -8px;
  /* left: 20px; */
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9998;
}