@value kardia-green, kardia-yellow, kardia-red from './variables/colors.css';

.bmiNormal {
  fill: kardia-green;
  border-left-color: kardia-green;
}
.bmiUnderweight {
  fill: kardia-yellow;
  border-left-color: kardia-yellow;
}
.bmiOverweight {
  fill: kardia-yellow;
  border-left-color: kardia-yellow;
}
.bmiObese {
  fill: kardia-red;
  border-left-color: kardia-red;
}
