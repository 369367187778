.ecgLoader {
  padding-top: 100px;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.pulse {
  stroke-dasharray: 800;
  animation: dash 2.5s infinite linear forwards;
}

.ecgLoader h3 {
  text-transform: none;
  margin-top: 25px;
  font-size: 22px;
  color: silver;
  letter-spacing: 0.06em;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 800;
  }
  100% {
    stroke-dashoffset: -800;

  }
}