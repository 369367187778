@value black from '../../assets/styles/variables/colors.css';

.filter {
  color: black;
}

.filter h3 {
  padding-bottom: 4px;
  text-transform: none;
  color: black;
}
