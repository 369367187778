.spectrogram_drawer {
  padding: 2px;
}

.container {
  width: 100%;
  margin-top: 10px;
  /* border: 2px solid gray; */
}

ul {
  list-style-type: disclosure-closed;
}
.selectedAudio {
  background: #1564b3;
  color: aliceblue;
}

.canvasspec {
  flex: 1 1 auto;
  background-color: black;
  width: 97%;
  height: 34vh;
}

canvas {
  width: 100%;
  height: 20vh;
}
.note {
  font-size: 13px;
  display: flex;
  gap: 8px;
  margin-bottom: 0.6rem;
}
.boxFeature {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.errorMessage{
  color:#CC3D3D;
  font-size: 13px;
}