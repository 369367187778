@value white from '../../../assets/styles/variables/colors.css';
@value mainFontFamily from '../../../assets/styles/variables/global.css';

.mainContainer {
  position: absolute;
  background-color: #f1f2f3 !important;
  top: 20%;
  left: 30%;
  z-index: 999;
  width: auto;
  height: auto;
  padding: 10px 10px 20px;
  margin: 4px;
  overflow: auto;
  border-radius: 10px;
  box-shadow: 5px 8px 5px 8px rgb(0 0 0 / 25%), 4px 5px 8px 6px rgb(0 0 0 / 20%),
    5px 3px 10px 10px rgb(0 0 0 / 25%) !important;
}

.header {
  margin: -20px -20px 10px -20px;
  padding: 10px 20px;
  background-color: darkgray;
  color: black;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
