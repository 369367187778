@keyframes spin {
    to { transform: rotate(1turn); }
  }
  
  .progress {
    position: relative;
    display: inline-block;
  
    animation: spin 850ms infinite;
    animation-timing-function: linear;
  }
  .small {
    composes: progress;
    width: 20px;
    height: 20px;
  }
  .small svg {
      width: 20px;
      height: 20px;
  }
  .xsmall {
    composes: progress;
    width: 16px;
    height: 16px;
  }
  .xsmall svg {
      width: 16px;
      height: 16px;
  }
  .large {
    composes: progress;
    width: 60px;
    height: 60px;
  }
  .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  