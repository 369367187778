.headerText {
  font-weight: 500;
  font-size: 25px;
  color: #142a39;
  line-height: 29px;
}
.gridHeader {
  display: flex;
  align-items: center;
  padding: 20px;
}
.hrStyle {
  margin-top: 0;
  color: #142a39;
}
.smallBox {
  width: 40px;
  height: 22px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.38);
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 13px 27px;
  margin-left: 10px;
  justify-content: center;
}
.recordingInfo {
  z-index: 1;
  padding: 8px 10px;
  min-height: 30px;
  position: absolute;
  right: 50px;
  top: 50px;
  background-color: #fff;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.recordingInfo > span {
  padding-left: 20px;
}
.recordingInfo > span:first-child {
  padding-left: 0;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 69vh;
  width: 100%;
}
