.ui.popup.popups::before
{
  box-shadow:none !important;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #efefefd4;
  background: transparent !important;
  bottom: -10px !important;
}
.ui.bottom.left.popup.popups::before{
  transform: rotate(90deg) !important;
  top: 20px;
  left: -15px;
}
.ui.top.left.popup.popups::before {
  transform: rotate(0deg) !important;
  top: auto;
  left: auto; 
}
.ui.bottom.right.popup.popups::before{
  top: 20px;
  right: -15px;
  bottom: auto;
  transform: rotate(-90deg);
}
.ui.top.right.popup.popups::before{
  transform: rotate(0deg);
  right: 21px;
  top: auto;
}