.telekardia{
.right_content {
    background-color: #F2F4F8;
    /*max-width: 1350px;*/
    /*min-width: 1240px;*/
    margin: 0 auto;
    padding: 0 0px;
}

header.page_head {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    padding: 26px 0 10px;
    position: relative;
}
.head h1.title {
    font-weight: 500;
    display: inline-block;
    font-size: 24px;
    color: #212121;
}
.head h1.title span {
    width: 22px;
    height: 6px;
    border-radius: 2px;
    background-color: #2D9F86;
    display: block;
    margin-top: 8px;
}
.page_head .head {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: calc(100% - 368px - 40px);
}
}