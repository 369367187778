@value popoverBorder, popoverShadow, silver, black, white, bg-white, light-grey, tableConfirmedColor from '../../../assets/styles/variables/colors.css';

.bloodPressureTable {
  height: 100%;
  position: relative;
}

.datePopover {
  margin-left: -16px;
}
.datePopoverWithSelection {
  margin-left: -70px;
}

.sourcePopover {
  margin-left: 78px;
}
.sourcePopoverWithSelection {
  margin-left: 55px;
}

.categoryPopover {
  margin-left: 78px;
}
.categoryPopoverWithSelection {
  margin-left: 55px;
}

.bpmPopover {
  margin-left: -75px;
}

.systolicPopover {
  margin-left: -75px;
}

.diastolicPopover {
  margin-left: -135px;
}