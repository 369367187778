.telekardia {
@media (min-width: 768px) and (max-width: 991.98px) {
    .dashboard_calendar .rbc-events-container .rbc-event-label, .dashboard_calendar .rbc-day-slot .rbc-event-content {
        padding-left: 5px !important;
        padding-right: 0px !important;
    }
    .right_container {
        min-width: 100%;
    }

    .width_330 {
        min-width: 225px !important;
        max-width: 225px !important;
    }

    .home_center {
        width: 100% !important;
    }
    .appt_notes {
        height:200px !important;
    }
    //.dashboard_calendar .rbc-label:first-letter() { color: transparent !important; }

    /*.dashboard_calendar .rbc-time-slot .rbc-label:nth-letter(even) {*/
    /*    display:none !important;*/
    /*    border:1px solid red*/
    /*}*/
     .dashboard_calendar .rbc-time-slot .rbc-label {
        white-space: pre-wrap !important;
        width: 55px;
         padding: 0;
    }
    .dashboard_calendar .rbc-time-content .rbc-time-gutter.rbc-time-column {
        width: 60px !important;
        text-align: center;
    }
    .cal_button .rbc-btn-group button {
        width: 90px;
    }
    .rbc-day-slot .rbc-event-label{
        width:100% !important;
    }
   .tags_small {
       min-height: 200px;
       max-height:auto
   }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .dashboard_calendar .rbc-events-container .rbc-event-label, .dashboard_calendar .rbc-day-slot .rbc-event-content {
        padding-left: 5px !important;
        padding-right: 0px !important;
    }
    .right_container {
        min-width: 100%;
    }

    .width_330 {
        min-width: 225px !important;
        max-width: 225px !important;
    }

    .home_center {
        width: 100% !important;
    }
    .dashboard_calendar .rbc-time-slot .rbc-label {
        white-space: pre-wrap !important;
        width: 55px;
        padding: 0;
    }
    .dashboard_calendar .rbc-time-content .rbc-time-gutter.rbc-time-column {
        width: 60px !important;
        text-align: center;
    }
    .cal_button .rbc-btn-group button {
        width: 110px;
    }
    .rbc-day-slot .rbc-event-label{
        width:100% !important;
    }

}

}