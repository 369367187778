/* @value black, kardia-green, kardia-orange from '../../../styles/variables/colors.css'; */

.teamInvitesTable {
  height: 100%;
  position: relative;
}

.emailPopover {
  margin-left: 0;
}

.emailPopoverWithSelection {
  margin-left: 0;
}

.cancelIcon {
  stroke: #142a39;
}
