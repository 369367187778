@value silver, black, medium-grey, teal, light-teal, white, kardia-black, kardia-orange, light-grey, lighter-grey, lightest-grey, placeholderGrey from "./variables/colors.css";
@value borderRadius from "./variables/global.css";
@value h1, h2, h2w, h3, sm from "./typography.css";

.textArea {
  font-weight: 400;
  width: 100%;
  border: 1px solid light-grey;
  border-radius: borderRadius;
  resize: none;
  outline: none;
  overflow: auto;
  padding: 8px 16px;
}
.textArea::-webkit-input-placeholder {
  color: placeholderGrey;
}
.textArea::-moz-placeholder {  /* Firefox */
  color: placeholderGrey;
}
.textArea:-ms-input-placeholder { /* IE10–11 */
  color: placeholderGrey;
}
.textArea::-ms-input-placeholder { /* Edge */
  color: placeholderGrey;
}
.textArea::placeholder { /* CSS Working Draft */
  color: placeholderGrey;
}
.newDefaultButton {
  padding: 8px 11px;
  outline: none;
  background-color: medium-grey;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid medium-grey;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.24);
}
.newDefaultButton:hover {
  background-color: lighter-grey;
  border: 1px solid medium-grey;
}
.newDefaultButton:focus {
  border: 1px solid lighter-grey;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}
.newDarkDefaultButton {
  composes: newDefaultButton;
  background-color: teal;
  border-color: teal;
  color: #fff;
}
.newDarkDefaultButton:hover {
  background-color: light-teal;
  border-color: light-teal;
}
.newDarkDefaultButton:disabled,
.newDarkDefaultButton:disabled:hover {
  opacity: 0.50;
  cursor: not-allowed;
  background-color:lighter-grey;
  border-color: medium-grey;
  color: hsl(0,0%,50%);
  stroke: medium-grey;
}
.newDefaultButton:focus {
  border: 1px solid light-teal;
}

.newLightDefaultButton {
  composes: newDefaultButton;
  background-color: lighter-grey;
  border-color: teal;
}
.newLightDefaultButton:hover {
  background-color:  light-teal;
}

.newDefaultFormInput {
  composes: h3;
  font-weight: normal;
  text-transform: none;
  padding: 8px;
  border: 1px solid lightest-grey;
  border-radius: 4px;
  color: black;
  background-color: white;
  outline: none;
  box-shadow: 0px 1px 2px 0px light-grey;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.newDefaultFormInput::placeholder {
  color: hsl(0,0%,50%)
}
.newDefaultFormInput:hover {
  border-color: teal;
}
.newDefaultFormInput:focus {
  border-color: light-teal;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}
.newDefaultFormInput:disabled {
  border: none;
  background-color: transparent;
  box-shadow: none;
  font-weight: bold;
  color: black;
  -webkit-text-fill-color: black;
}
.newDefaultFormInput::-webkit-input-placeholder {
  font-weight: normal;
  color: silver;
  -webkit-text-fill-color: silver;
}
.newDefaultFormInput::-moz-placeholder {  /* Firefox */
  font-weight: normal;
  color: silver;
  -webkit-text-fill-color: silver;
}
.newDefaultFormInput:-ms-input-placeholder { /* IE10–11 */
  font-weight: normal;
  color: silver;
  -webkit-text-fill-color: silver;
}
.newDefaultFormInput::-ms-input-placeholder { /* Edge */
  font-weight: normal;
  color: silver;
  -webkit-text-fill-color: silver;
}
.newDefaultFormInput::placeholder { /* CSS Working Draft */
  font-weight: normal;
  color: silver;
  -webkit-text-fill-color: silver;
}
