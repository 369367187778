.popoverToolTipWraper {
  width: 280px;
  height: 142px;
  background-color: #fff;
  padding: 5px 0;
  position: fixed;
  z-index: 9999 !important;  
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.12), 0px 1px 48px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  right: 264px;
  margin-top: 75px;
}

.headerInfo {
  margin: 16px 0px 16px 20px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.popoverToolTipWraper .popoverClose {
  position: absolute;
  top: 13px;
  right: 13px;
}

.popoverToolTipContent {
  margin: 0px 20px 0px 20px;
  width: 240px;
  white-space: initial;
  font-weight: normal;
  line-height: 20px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .popoverToolTipWraper{
    right: auto;
    margin-top: -35px;
  }
}


.popoverToolTipWraperForEnrolltable {
  /* width: 280px;
  height: 142px; */
  position: absolute;
  width: 160px;
  background-color: #fff;
  padding: 5px 0;
  /* position: fixed; */
  /* z-index: 9999 !important;   */
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.12), 0px 1px 48px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  /* right: 264px;
  margin-top: 75px; */
}

.popoverToolTipContentEnroll {
  /* margin: 0px 20px 0px 20px; */
  /* width: 240px; */
  margin-top: -15px;
  white-space: initial;
  font-weight: normal;
  line-height: 20px;
  padding-left: 3px;
}

.popoverToolTipWraperForEnrolltable .popoverCloseEnroll {
  position: absolute;
  /* top: 65px;
  right: 20px; */
  top: 5px;
  right: 5px;
}