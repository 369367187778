@value kardia-green, kardia-orange, kardia-yellow, grey-2 from './variables/colors.css';

.ekgNormal {
  fill: kardia-green;
  border-left-color: kardia-green;
}

.ekgAbnormal {
  fill: kardia-orange;
  border-left-color: kardia-orange;
}

.ekgOther {
  fill: kardia-yellow;
  border-left-color: kardia-yellow;
}

.ekgNoAnalysis {
  fill: grey-2;
  border-left-color: grey-2;
}

.stable {
  fill: kardiaRx-blue;
  border-left-color: kardiaRx-blue;
}

.mdn {
  fill: kardiaRx-red;
  border-left-color: kardiaRx-red;
}
