.telekardia{
@value m from '../../styles/typography.css';

.secondsText {
	composes: m;
}
}
.secondsText{
	text-anchor: start;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}