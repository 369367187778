@value darker-grey, silver, dark-blue, kardia-blue, black, lightest-grey from "./variables/colors.css";
@value mainFontFamily, aFontSize, labelFontSize, mFontSize, bFontSize, hThreeFontSize, hTwoFontSize, hOneFontSize, secondaryFontFamily from "./variables/global.css";

body, * {
  font-family: mainFontFamily;
}

h1, .h1 {
  font-size: hOneFontSize;
  font-weight: normal;
  color: black;
  font-family: secondaryFontFamily;
}

.h1w {
  composes: h1;
  color: #fff;
}

h2, .h2 {
  font-size: hTwoFontSize;
  font-weight: 500;
  color: black;
  font-family: secondaryFontFamily;
}

.h2w {
  composes: h2;
  color: #fff;
}

h3, .h3 {
  font-size: hThreeFontSize;
  font-weight: 500;
  color: silver;
  text-transform: uppercase;
  font-family: mainFontFamily;
}

h4 {
  font-size: bFontSize;
  font-family: mainFontFamily;
}

.h3w {
  composes: h3;
  color: #fff;
}

.b {
  font-size: bFontSize;
  font-weight: normal;
  color: black;
  font-family: mainFontFamily;
}

.bw {
  composes: b;
  color: #fff;
}

.sb {
  font-size: bFontSize;
  font-weight: normal;
  color: silver;
  font-family: mainFontFamily;
}

.bm {
  font-size: mFontSize;
  font-weight: 500;
  color: black;
  font-family: mainFontFamily;
}

.sm {
  font-size: mFontSize;
  font-weight: 400;
  color: silver;
  font-family: mainFontFamily;
}

a, .a {
  font-size: aFontSize;
  font-weight: 500;
  color: kardia-blue;
  text-decoration: none;
  font-family: mainFontFamily;
}
a:hover, .a:hover {
  color: dark-blue;
  cursor: pointer;
}
.m {
  color: black;
  font-size: mFontSize;
  font-family: mainFontFamily;
}
.am {
  composes: a;
  font-size: mFontSize;
}
.mw {
  color: white;
  font-size: mFontSize;
  font-family: mainFontFamily;
}
.lbl {
  color: silver;
  font-size: labelFontSize;
  font-family: mainFontFamily;
}
