@value light-grey, lighter-grey from '../../../assets/styles/variables/colors.css';
@value h1 from '../../../assets/styles/typography.css';
@value newDarkDefaultButton, newLightDefaultButton from '../../../assets/styles/formElements.css';


.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -33%);
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  background-color: #ffffff;
}

.header {
  composes: h1;
  border-bottom: 1px solid light-grey;
  padding: 8px;
  margin: 0 8px 0;
}

.body {
  padding: 24px 16px 30px;
}
.body dt {
  width: 30%;
  display: inline-block;
  line-height: 1.5;
  text-align: right;
  padding-right: 10px;
}
.body dd {
  width: 70%;
  display: inline-block;
  line-height: 1.5;
  font-weight: 600;
}

.body p {
  padding-bottom: 8px;
}

.body strong {
    font-weight: 600;
}

.body p:last-child {
  padding-bottom: 0;
}

.controls {
  width: 100%;
}

.controls button {
  width: 50%;
  display: inline-block;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.controls button:first-of-type {
  border-bottom-right-radius: 0;
}
.controls button:last-of-type {
  border-bottom-left-radius: 0;
}

.primaryButton {
  composes: newDarkDefaultButton;
  font-size: 16px;
}

.secondaryButton {
  composes: newLightDefaultButton;
  border-color: lighter-grey;
  font-size: 16px;
}
