.headerText {
  font-family: 'Work Sans';
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
}
.divWrapperOverride {
  padding: 16px;
}
.divHeaderWrapper {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.chipHolder {
  margin-top: 16px;
  display: flex;
}
.holder {
  margin-top: 8px;
}
.holder_single {
  margin: 0 !important;
  display: flex;
}
.holder_single > :nth-child(1) {
  font-weight: 600;
}
.holder_single > :nth-child(2) {
  margin-left: 10px;
}
.flexWrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 20px;
}
.weekController {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.formControl {
  min-width: 120;
}
.callTypeSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.distanceFromIcon {
  margin-left: 20px;
}
.customTabRoot {
  color: #142a39;
}
.customTabIndicator {
  /* background-color: #2d9f86; */
  height: 4px;
}
.tabRoot {
  font-size: 14px !important;
  min-width: 166;
  font-weight: 700;
  font-family: 'Work Sans';
}
