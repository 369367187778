.divWrapperOverride {
  padding-bottom: 32px;
}
.divHeaderWrapper {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.chipHolder {
  margin-top: 16px;
  display: flex;
}
.holder {
  margin-top: 8px;
}
.holder_single {
  margin: '0 !important';
  display: flex;
}
.holder_single > :nth-child(1) {
  font-weight: 600;
}
.holder_single > :nth-child(2) {
  margin-left: 10px;
}
.flexWrapper {
  display: flex;
  justify-content: space-between;
}
.weekController {
  display: flex;
  align-items: center;
  justify-content: center;
}
.formControl {
  min-width: 120;
}
.tableRowStyle:nth-child() {
  background-color: #f0f8f5 !important;
}
.tableRowStyle:hover {
  background-color: #f5f5f5 !important;
}

.callTypeSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.distanceFromIcon {
  margin-left: 20px;
}
.tableHeaderText {
  font-size: 1.4rem;
  font-weight: 400;
}

.bpm {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: 'Work Sans';
}
.bpmspan {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  padding-left: 4px;
  font-family: 'Work Sans';
}
.pdfHistory {
  height: 'auto';
  width: 100%;
  border-radius: '4px';
  box-shadow: '0px 2px 3px 0px light-grey';
  background-color: '#FFFFFF';
  position: 'relative';
}
