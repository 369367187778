@value white, tableGridGrey, black, light-teal, teal from '../../../assets/styles/variables/colors.css';
@value popover from '../Popovers.module.css';

.listPopover {
  composes: popover;
  min-width: 185px;
  padding: 11px 0;
}

.list {

}

.row {
  text-align: left;
  display: block;
  width: 100%;
  background-color: white;
  color: black;
  font-size: 14px;
  font-weight: 600;
  font-family: proxima-nova, "Helvetica Neue", helvetica, arial, sans-serif !important;
  border: none;
  border-bottom: 1px solid tableGridGrey;
  padding: 8px 0 8px 40px;
  outline: none;
  cursor: pointer;
}
.row:last-child {
  border-bottom: none;
}

.bullet {
  width: 6px;
  height: 6px;
  border: 1px solid teal;
  background-color: teal;
  border-radius: 50%;
  margin-right: 10px;
  transition: margin 0.2s;
  display: inline-block;
}
.activeRow {
  composes: row;
  padding-left: 34px;
  color: teal;
}
