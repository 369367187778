@value dark-white, white, light-grey, lighter-grey from './variables/colors.css';
@value h3, h2, m from './typography.css';
@value contentWrapper from './page.css';
@value newDarkDefaultButton from './formElements.css';

.formWrapper {
  composes: contentWrapper;
  background-color: white;
  overflow-y: scroll;
}
.formTop {
  padding: 0 30px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.formTitle {
  composes: h2;
  font-weight: 600;
  font-size: 21px;
  /* margin: 0 0 13px 25px; */
  margin: 0 0 13px 0px;
  padding-top: 24px;
}
.formSubtitle {
  composes: h3;
  /* margin: 0 0 13px 25px; */
  margin: 0 0 13px 0px;
  font-size: 15px;
  text-transform: none;
}
.formSection {
  background-color: dark-white;
  padding: 36px;
  border-radius: 4px;
  border: 1px solid lighter-grey;
  margin-bottom: 8px;
}
.formSection label {
  position: relative;
  width: 240px;
}
.placeholder {
  width: 240px;
}
.formSection > label {
  width: 100%;
}
.formSectionRow {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.formSectionRow:first-of-type {
  margin-top: 0;
}
.submitWrapper {
  display: flex;
  justify-content: center;
  margin: 41px 0;
}
.buttonWrapper {
  /* background-color: white; */
  padding: 10px;
  border-radius: 4px;
}
.submit {
  composes: newDarkDefaultButton;
  composes: m;
  color: white;
  text-transform: uppercase;
  padding: 11px 38px;
}
