@value h1 from './typography.css';
@value darker-grey, dark-white, light-grey, white, black, bg-white, teal from './variables/colors.css';
@value contentWidth, mainFontFamily from './variables/global.css';
@value headerHeight 70px;

.pageContainer {
  background-color: bg-white;
  max-width: contentWidth;
  min-width: contentWidth;
  margin: 0 auto;
  /* padding: 0 20px; */
}
.headerText {
  composes: h1;
  font-weight: 500;
  display: inline-block;
  font-size: 24px;
  color: black;
}
.headerLine {
  width: 40px;
  height: 6px;
  border-radius: 2px;
  background-color: #1976d2;
  display: block;
  margin-top: 8px;
}
.contentWrapper {
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px light-grey;
  background-color: white;
  position: relative;
}
/* .popoverBlanket {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
} */

/* Patient Name Header */
.headerWrapper {
  display: inline-block;
  width: 100%;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; */
  /* height: 38px; */
}
.nameText {
  composes: headerText;
  font-size: 18px;
  color: darker-grey;
  display: inline-block;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  white-space: normal;
  word-break: break-all;
  margin-right: 24px;
}
.yearsOfAge {
  display: inline-block;
  font-family: mainFontFamily;
  font-size: 16px;
  font-weight: 800;
  padding: 8px 12px;
  background-color: dark-white;
  border-radius: 4px;
  min-height: 30px;
  margin-right: 24px;
}
.mrnTitle {
  composes: nameText;
  margin-right: 16px;
}
.mrnValue {
  composes: yearsOfAge;
}
