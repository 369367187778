@value lighter-grey, light-grey, teal, light-teal, lighter-teal, lightest-teal, tableGridGrey, white, silver from '../../../../assets/styles/variables/colors.css';

/* @value ch 32px; */
@value ch 49px;
@value cf 44px;
@value hbb 1px;

.headerWithControls {
  border-bottom: hbb solid light-grey;
}

.columnWrapper {
  height: ch;
  background-color: #e2e7e3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.columnHeader {
  height: ch;
  background-color: #e2e7e3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  font-family: proxima-nova, 'Helvetica Neue', helvetica, arial, sans-serif !important;
  position: relative;
  cursor: pointer;
  user-select: none;
  /* border-top: 2px solid teal; */
  text-transform: uppercase;
}
.columnHeaderNoSort {
  composes: columnHeader;
  cursor: default;
}

.sortDirectionNone {
  width: 7px;
  height: 7px;
  margin-left: 8px;
  display: inline-block;
}
.sortDirectionDesc {
  border-style: solid;
  border-color: silver;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 7px;
  width: 7px;
  margin-left: 8px;
  margin-bottom: 5px;
  transform: rotate(135deg);
  vertical-align: top;
}

.sortDirectionAsc {
  composes: sortDirectionDesc;
  margin-bottom: 0;
  transform: rotate(-45deg);
}

.columnFilter {
  height: cf;
  width: 100%;
  background-color: lightest-teal;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid tableGridGrey;
  user-select: none;
  position: relative;
  /* padding-right: 10px; */
}

.lastColumnFilter {
  composes: columnFilter;
  border-right: none;
}

.filterButton {
  z-index: 12;
  height: 29px;
  width: 70px;
  background-color: white;
  color: silver;
  font-size: 11px;
  font-weight: 700;
  font-family: proxima-nova, 'Helvetica Neue', helvetica, arial, sans-serif !important;
  text-transform: uppercase;
  border: none;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  outline: none;
}

.filterButtonActive {
  composes: filterButton;
  background-color: light-teal;
  color: white;
}

.popoverWrapper {
  text-transform: none;
  z-index: 13;
  position: absolute;
  top: 90%;
  left: 0;
}
