@value kardia-green, kardia-orange, kardia-grey from '../../../assets/styles/variables/colors.css';

.teamMembersTable {
  height: 100%;
  position: relative;
}

.namePopover,
.emailPopover {
  margin-left: -75px;
}
.countryPopover {
  margin-left: -50px;
}
.regionPopover {
  margin-left: -60px;
}

.namePopoverWithSelection,
.emailPopoverWithSelection {
  margin-left: 0;
}

.adminPermissionPopover {
  margin-left: -18px;
}

.physicianPermissionPopover {
  margin-left: -24px;
}

.hasPermission {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #142a39;
  padding: 4px 6px;
  border-radius: 99px;
  margin: 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  width:70%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2.5px;
  border: 1.5px solid kardia-green;
}
.doesNotHavePermission {
  composes: hasPermission;
  border: 1.5px solid kardia-grey;
}
